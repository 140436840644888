import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const StripeBuyButton = () => {
    const inputEmail = useSelector((state) => state.signUpEmail);
    const inputRadius = useSelector((state) => state.radius);
    const buttonRef = useRef(null);

    useEffect(() => {
        // Load the Stripe Buy Button script
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js';
        script.async = true;
        document.body.appendChild(script);
        console.log(inputEmail);
        console.log(inputRadius);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <stripe-buy-button
                ref={buttonRef}
                buy-button-id="buy_btn_1Pdq352NcE2VbmHRMz8pGy4T"
                publishable-key="pk_live_51PXbbf2NcE2VbmHR4xNQ4d7yBJfawxAUIFFP6cr9yYmJAWx3csGoFxP09Xg3v97YeOLxm319KHwUOoTW9e7G2Gyk00EIv4TMLN"
            />
        </div>
    );
};

export default StripeBuyButton;