export const changeUsername = () => ({
    type: 'CHANGE_USERNAME',
});

export const deleteUsername = () => ({
    type: 'DELETE_USERNAME',
});


export const setSignUpEmail = (email) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: 'SET_SIGNUP_EMAIL',
            payload: email
        });
        resolve();
    });
}

export const deleteSignUpEmail = () => ({
    type: 'DELETE_SIGNUP_EMAIL',
})

export const setRadius = (radius) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: 'SET_RADIUS',
            payload: radius
        });
        resolve();
    });
}

export const removeRadius = () => ({
    type: 'REMOVE_RADIUS'
})

export const setLocation = (location) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: 'SET_LOCATION',
            payload: location
        })

        resolve();
    })
}

export const removeLocation = () => ({
    type: 'REMOVE_LOCATION'
})

export const setPaymentScreenFlag = (flag) => ({
    type: 'SET_PAYMENT_SCREEN_FLAG',
    payload: flag
})

export const resetPaymentScreenFlag = () => ({
    type: 'RESET_PAYMENT_SCREEN_FLAG'
})