import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

// Initial states
const initialSignUpEmailState = '';
const initialRadiusState = '0';
const initialLocationState = '';
const initialPaymentScreenFlag = false;

// Reducers
const signUpEmail = (state = initialSignUpEmailState, action) => {
    switch (action.type) {
        case 'SET_SIGNUP_EMAIL':
            return action.payload;
        case 'DELETE_SIGNUP_EMAIL':
            return initialSignUpEmailState; // Reset to initial state
        default:
            return state; // Return current state for unknown actions
    }
};

const radius = (state = initialRadiusState, action) => {
    switch (action.type) {
        case 'SET_RADIUS':
            return action.payload;
        case 'REMOVE_RADIUS':
            return initialRadiusState; // Reset to initial state
        default:
            return state; // Return current state for unknown actions
    }
};

const location = (state = initialLocationState, action) => {
    switch (action.type) {
        case 'SET_LOCATION':
            return action.payload;
        case 'REMOVE_LOCATION':
            return initialLocationState; // Reset to initial state
        default:
            return state; // Return current state for unknown actions
    }
};

const paymentScreenFlag = (state = initialPaymentScreenFlag, action) => {
    switch (action.type) {
        case 'SET_PAYMENT_SCREEN_FLAG':
            return action.payload;
        case 'RESET_PAYMENT_SCREEN_FLAG':
            return initialPaymentScreenFlag;
        default:
            return state;
    }
}

// Root reducer
const rootReducer = combineReducers({
    signUpEmail,
    radius,
    location,
    paymentScreenFlag
});

// Create store
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
