import React from 'react';
import { Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import foodometre from './Foodometre.png'
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Hero from './Hero';
import Product from './Product';
import InfoLanding from './InfoLanding';
import PaymentSuccessScreen from './PaymentSuccessScreen';
import { APIProvider } from '@vis.gl/react-google-maps'

function App() {
  return (
    <Routes>
      <Route path="/" element={<InfoLanding />} />
      <Route path="/Home" element={<InfoLanding />} />
      <Route path="/Product" element={
        <APIProvider apiKey={'AIzaSyCBHQT3ISdGKNfWn7sxdb-EWNNpotEDYjM'}>
          <Product />
        </APIProvider>
      } />
      <Route path="/PaymentSuccess" element={<PaymentSuccessScreen />} />
    </Routes>
  );
}

export default App;
