import React, { useState, useRef, useEffect } from 'react';

export default function RadiusSlider(props) {
    // const [currentValue, setCurrentValue] = useState(500);
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = useRef(null);

    const min = 100;
    const max = 2000;

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (event) => {
        if (!isDragging || !sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const width = rect.width;

        let newValue = (offsetX / width) * (max - min) + min;
        newValue = Math.max(min, Math.min(max, newValue)); // Clamp value between min and max

        props.setCurrentValue(newValue);
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const percentage = ((props.currentValue - min) / (max - min)) * 100;

    return (
        // <div className="flex w-64 m-auto items-center h-32 justify-center">
        <div className="py-1 relative min-w-full z-1" ref={sliderRef}>
            <div className="h-2 bg-gray-200 rounded-full">
                <div className="absolute h-2 rounded-full bg-teal-600 w-0" style={{ width: `${percentage}%` }}></div>
                <div
                    className="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer"
                    unselectable="on"
                    onselectstart="return false;"
                    onMouseDown={handleMouseDown}
                    style={{ left: `${percentage}%`, zIndex: 1 }}
                >
                    <div className="relative -mt-2 w-1">
                        <div className="absolute z-40 opacity-100 bottom-100 mb-2 left-0 min-w-full" style={{ marginLeft: '-20.5px' }} >
                            <div className="relative shadow-md">
                                <div className="bg-black -mt-8 text-white truncate text-xs rounded py-1 px-4 radius-text">{Math.round(props.currentValue)}</div>
                                <svg className="absolute text-black w-full h-2 left-0 top-100" x="0px" y="0px" viewBox="0 0 255 255">
                                    <polygon className="fill-current" points="0,0 127.5,127.5 255,0"></polygon>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute text-gray-800 -ml-1 bottom-0 left-0 -mb-6">100 m</div>
                <div className="absolute text-gray-800 -mr-1 bottom-0 right-0 -mb-6">2000 m</div>
            </div>
        </div>
        // </div >
    );
}
