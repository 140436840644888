import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import demoVideo from './demo/jao-asking-questions-BEST.mp4'

const features = [
    {
        name: 'Push to deploy.',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'SSL certificates.',
        description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
        icon: LockClosedIcon,
    },
    {
        name: 'Database backups.',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
        icon: ServerIcon,
    },
]

export default function ProductDemo() {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-indigo-600">Immediate answers</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">See our live demo</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Don't believe us? See for yourself on the right how Jaoo helped this user find sushi near him, filter for salmon free options, and then find the cheapest among them.
                            </p>

                        </div>
                    </div>
                    <video
                        src={demoVideo}
                        width={2432}
                        height={1542}
                        style={{ objectFit: 'contain' }}
                        className="-mb-12 w-[57rem] max-w-l rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                        controls // Adds play, pause, and other controls
                        autoPlay // Optionally, start playing the video automatically
                        loop // Optionally, loop the video
                        muted // Optionally, mute the video
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    )
}
