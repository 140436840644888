import React, { useState, useEffect, useRef, Fragment } from 'react';
import io from 'socket.io-client';
import TextInput from './TextInput';
import ReactMarkdown from 'react-markdown';
import Markdown from 'react-markdown'
import 'github-markdown-css/github-markdown.css';

const WebSocketCall = (props) => {
    const [message, setMessage] = useState(''); // For storing the input from user
    const [messages, setMessages] = useState([]); // For storing all messages
    const [currQuestion, setCurrQuestion] = useState('');
    const [currAnswer, setCurrAnswer] = useState('');
    const socketRef = useRef(null); // Ref to store the socket instance


    useEffect(() => {
        const socket = io('https://api.jaoo.ca', {
            transports: ['websocket'],
            pingInterval: 25000,
            pingTimeout: 60000,
        });

        socketRef.current = socket;

        socket.on('connect', () => console.log('Connected to server!'));

        socket.on('message', (msg) => {
            setMessages((prevMessages) => [...prevMessages, msg]);
        });

        // Listening for messages from the server
        socket.on('message_from_server', (msg) => {
            setMessages(prevMessages => [...prevMessages, msg.data]);
            setCurrAnswer(msg.data);

            var newObj = {
                question: msg.question,
                answer: msg.data
            }

            if (props.faqQuestions.length >= 10) {
                // remove the value at index 0
                props.setFaqQuestions((prevMessages) => [...prevMessages.slice(1), newObj]);
            } else {
                props.setFaqQuestions((prevMessages) => [...prevMessages, newObj]);
            }

            setMessage(''); // Clear input after sending
        });

        // Clean up the effect by disconnecting the socket when the component unmounts
        return () => socket.disconnect();
    }, [props]);

    const sendMessage = () => {
        // FOR SENDING TO BACKEND
        if (message) {
            var dataToSend = {
                message: message,
                email: props.email,
                test: props.email,
                location: props.position
            };

            if (socketRef.current) {
                socketRef.current.emit('message_from_client', dataToSend); // Send message to the server
                setCurrQuestion(message);
                setCurrAnswer('Loading...');
            }
            // props.setFaqQuestions((prevMessages) => [...prevMessages, newObj]);
        }
    };

    return (
        <div>
            <p className="mt-6 text-gray-500">You're signed in! View your location on the left hand side, and ask questions to your custom AI below.</p>
            <p className="mt-6 text-gray-500">View previous responses for your chat on the left hand side right under the map.</p>

            <TextInput text={message} setText={setMessage} sendMsg={sendMessage} />

            <dl>
                <Fragment key={currQuestion}>

                    {/* 
                    <dt className="mt-10 font-medium text-gray-900">{currQuestion}</dt>
                    <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                        {/**  <Markdown children={currAnswer} />*/}
                    {/* <div className="markdown-body">
                        <ReactMarkdown>{currAnswer}</ReactMarkdown>
                    </div>
                </dd>*/}
                    <dt className="mt-10 font-medium text-gray-900">{currQuestion}</dt>
                    <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                        <div className="markdown-body">
                            <ReactMarkdown>{currAnswer}</ReactMarkdown>
                        </div>
                        <style jsx>{`
    .markdown-body {
      background-color: white; /* or your desired color */
      color: black; /* or your desired color */
    }
  `}</style>
                    </dd>
                </Fragment>
            </dl>
        </div >
    );
};

export default WebSocketCall;