import { Link } from 'react-router-dom';

const navigation = [
    { name: 'Home', href: '#' },
    { name: 'Product', href: '#' }
]



export default function PaymentSuccessScreen() {
    return (
        <>
            <div className="bg-white grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">

                <header className="bg-white absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                {/* <img
                                className="h-8 w-auto"
                                // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                src={screenshot}
                                alt=""
                            /> */}
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) => (
                                <Link to={"/" + item.name} >
                                    <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                        {item.name}
                                    </a>
                                </Link>

                            ))}
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">

                        </div>
                    </nav>
                </header>

                <main className="mx-auto w-full max-w-7xl px-6 py-8  lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
                    <div className="max-w-lg">
                        <p className="text-base font-semibold leading-8 text-indigo-600">Hey!</p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thank you</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            We are beyond honored that you chose to use Jaoo to help you find restaurants and food selections. From here, we'll start setting up your account, and getting restaurant data.
                        </p>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Within 1-2 days, you can expect an email from us confirming your account setup. Only then will you be able to login. If you have any questions or concerns, please reach out to <b>product@jaoo.ca</b>
                        </p>
                        <div className="mt-10">


                            <Link to={"/"} >
                                <a className="text-sm font-semibold leading-7 text-indigo-600">
                                    <span aria-hidden="true">&larr;</span> Back to home
                                </a>
                            </Link>
                        </div>
                    </div>
                </main>
                <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
                    <div className="border-t border-gray-100 py-10">

                    </div>
                </footer>
                <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
                        className="absolute inset-0 h-full w-full object-cover"
                    />
                </div>
            </div>
        </>
    )
}
