import { Fragment, useState, useEffect, useCallback, useRef } from 'react'
import { StarIcon, ChatBubbleLeftIcon } from '@heroicons/react/20/solid'
import { Tab } from '@headlessui/react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Link } from "react-router-dom"
import { APIProvider, Map, Marker, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useLoadScript } from '@react-google-maps/api';
import { io } from "socket.io-client";
import Alert from "./Alert";
import WebSocketCall from "./WebSocketCall";
import LoadingModal from './LoadingModal';
import ReactMarkdown from 'react-markdown';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import AWS from 'aws-sdk';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import RadiusSlider from './RadiusSlider';
import { useSelector, useDispatch } from 'react-redux';
import { setLocation, setRadius, setSignUpEmail, setPaymentScreenFlag } from './actions/index';
import StripePaymentButton from './StripePaymentButton';
import { useStripe } from '@stripe/react-stripe-js';

// TODO LISt
// get state variables working 
// create the next window for the payment integration 
// on payment success -> run the register user API call with the redux store variables

// https://github.com/reach/reach-ui/issues/916 -> a bunch of componenets for reach to be compatible for react 18 are yet to be complete 
// can contribute via open source but it may be time consuming given that I have to launch the MVP
// could be a good opportunity though if I have the time to contribute 
// basically I just have to find a replacement for the reach library 


const navigation = [
    { name: 'Home', href: '#' },
    { name: 'Product', href: '#' }
]

const product = {
    name: 'Jaoo Food Search',
    version: { name: '1.0', date: 'July 1st, 2024', datetime: '2024-07-01' },
    price: '$220',
    description:
        'Get started with our tool below. No extensive sign up required, first tell us if you\'re first a time user or already subscribed.',
    highlights: [
        '200+ SVG icons in 3 unique styles',
        'Compatible with Figma, Sketch, and Adobe XD',
        'Drawn on 24 x 24 pixel grid',
    ],
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-05-product-01.jpg',
    imageAlt: 'Sample of 30 icons with friendly and fun details in outline, filled, and brand color styles.',
}
const reviews = {
    average: 4,
    featured: [
        {
            id: 1,
            rating: 5,
            content: `
        <p>This icon pack is just what I need for my latest project. There's an icon for just about anything I could ever need. Love the playful look!</p>
      `,
            date: 'July 16, 2021',
            datetime: '2021-07-16',
            author: 'Emily Selman',
            avatarSrc:
                'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
        },
        {
            id: 2,
            rating: 5,
            content: `
        <p>Blown away by how polished this icon pack is. Everything looks so consistent and each SVG is optimized out of the box so I can use it directly with confidence. It would take me several hours to create a single icon this good, so it's a steal at this price.</p>
      `,
            date: 'July 12, 2021',
            datetime: '2021-07-12',
            author: 'Hector Gibbons',
            avatarSrc:
                'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
        },
        // More reviews...
    ],
}

const license = {
    href: '#',
    summary:
        'For personal and professional use. You cannot resell or redistribute these icons in their original or modified state.',
    content: `
    <h4>Overview</h4>
    
    <p>For personal and professional use. You cannot resell or redistribute these icons in their original or modified state.</p>
    
    <ul role="list">
    <li>You\'re allowed to use the icons in unlimited projects.</li>
    <li>Attribution is not required to use the icons.</li>
    </ul>
    
    <h4>What you can do with it</h4>
    
    <ul role="list">
    <li>Use them freely in your personal and professional work.</li>
    <li>Make them your own. Change the colors to suit your project or brand.</li>
    </ul>
    
    <h4>What you can\'t do with it</h4>
    
    <ul role="list">
    <li>Don\'t be greedy. Selling or distributing these icons in their original or modified state is prohibited.</li>
    <li>Don\'t be evil. These icons cannot be used on websites or applications that promote illegal or immoral beliefs or activities.</li>
    </ul>
  `,
}



export default function Product() {
    const [position, setPosition] = useState({ lat: 53.54992, lng: 10.00678 });
    const [showForm, setShowForm] = useState(false);
    const [firstTimeUser, setFirstTimeUser] = useState(false)
    const [socketInstance, setSocketInstance] = useState("");
    const [loading, setLoading] = useState(true);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [showWebSocket, setShowWebSocket] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const [signedIn, setSignedIn] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [showInvalidRadiusAlert, setShowInvalidRadiusAlert] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const abortController = new AbortController();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [placeSelected, setPlaceSelected] = useState("");
    const [sliderValue, setSliderValue] = useState(100);

    const inputLocation = useSelector(state => state.location);
    const inputRadius = useSelector(state => state.radius);
    const inputEmail = useSelector(state => state.signUpEmail);
    const inputPaymentScreenFlag = useSelector(state => state.paymentScreenFlag);
    const dispatch = useDispatch();
    const stripe = useStripe();


    const { isLoaded } = useLoadScript({
        // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        googleMapsApiKey: "AIzaSyCBHQT3ISdGKNfWn7sxdb-EWNNpotEDYjM",
        libraries: ['places']
    });

    const PlaceAutocomplete = ({ onPlaceSelect }) => {
        const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
        const inputRef = useRef(null);
        const places = useMapsLibrary("places");
        const [locationString, setLocationString] = useState("");

        useEffect(() => {
            if (!places || !inputRef.current) return;

            const options = {
                fields: ["geometry", "name", "formatted_address"],
            };

            setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
            console.log("setting autocomplete ")
            console.log(inputRef.current)
            setLocationString(placeSelected.formatted_address);
        }, [places]);
        useEffect(() => {
            if (!placeAutocomplete) return;

            placeAutocomplete.addListener("place_changed", () => {
                console.log("place changed ")
                onPlaceSelect(placeAutocomplete.getPlace());
            });
        }, [onPlaceSelect, placeAutocomplete]);
        return (
            <div className="autocomplete-container">
                <input
                    id="location-text"
                    name="location-text"
                    // required
                    onInput={(e) =>
                        setLocationString(e.target.value)}
                    className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    // placeholder="Enter your location (e.g. 150 King Street, Toronto, Ontario)"
                    value={locationString}
                    placeholder="Enter your address"
                    ref={inputRef}
                />
            </div>
        );
    };

    const handleSubscribe = async (e) => {
        AWS.config.update({
            region: 'us-east-2',
            credentials: new AWS.Credentials({
                // accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
                accessKeyId: process.env.REACT_APP_AK,
                secretAccessKey: process.env.REACT_APP_SK
            })
        });

        console.log(AWS.config.credentials);
        console.log(process.env);

        var emailInput = document.getElementById("email-address").value;

        const lambda = new AWS.Lambda();

        const params = {
            FunctionName: 'twilioTextWithEmailLambda',
            InvocationType: 'RequestResponse', // 'Event' for async
            Payload: JSON.stringify({ email: emailInput + " signed up!" }) // Your payload here
        };

        lambda.invoke(params, (err, data) => {
            if (err) {
                console.error(err);
            }
        });
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function showProductButtons() {
        return (
            <div>
                <p className="mt-6 text-gray-500">{product.description}</p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                    <button
                        onClick={() => {
                            setShowForm(true); setFirstTimeUser(true)
                        }}
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                        First Time User
                    </button>
                    <button
                        onClick={() => {
                            setShowForm(true); setFirstTimeUser(false)
                        }}
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                        Already Subscribed
                    </button>
                </div>


                {/** 
                <div>
                    <WebSocketCall faqQuestions={faqs} setFaqQuestions={setFaqs} />
                </div>
                    */}
            </div >
        )
    }

    function handleResponse(responseData, email) {
        if (responseData['result'] == "Invalid radius") {
            // show error response pop up
            setShowLoadingModal(false);
            setShowInvalidRadiusAlert(true);
            return
        }

        setShowInvalidRadiusAlert(false);
        // convert each element in location array from string to a number

        // var newPosition = responseData['location'].map(Number)

        // setPosition({ lat: newPosition[0], lng: newPosition[1] });

        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ email: email })
        // }
        handleSubscribe();

        //fetch('http://ec2-18-191-214-35.us-east-2.compute.amazonaws.com:5000/returnUser', requestOptions)
        // fetch('https://api.jaoo.ca/returnUser', requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //         // do a web socket connection here
        //         // setSignedIn(true);
        //         // setUserEmail(email);
        //         // setShowLoadingModal(false);

        //         // we really just want to send myself and email confirmation and then have the user just see 
        //         // that they will be notified when their account is set up
        //         // don't process any automated self login here 

        //     });
    }

    const createStripeSession = async (inputEmail, inputLocation, stringSliderValue) => {
        try {
            const response = await fetch('https://api.jaoo.ca/create_stripe_session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: inputEmail,
                    location: inputLocation,
                    radius: stringSliderValue
                }),
            })

            const { sessionId } = await response.json();

            if (stripe) {
                await stripe.redirectToCheckout({ sessionId });
            }
        } catch (error) {
            console.log(error);
        }
    }

    function showFirstTimeUserForm() {
        return (
            <div>
                <p className="mt-6 text-gray-500">Simply enter your email, desired location, and radius and we'll handle all the data processing for your location. Once done, you can use your email as an access code to load up your profile when re-visiting the site.</p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                    <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
                        <div className="sm:col-span-2 sm:col-start-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                Email
                            </label>
                            <div className="flex gap-x-4">
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Enter your email"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                Location
                            </label>
                            <div className="flex gap-x-4">
                                {/* <input
                                    id="location-text"
                                    name="location-text"
                                    type="text"
                                    autoComplete="text"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Enter your location (e.g. 150 King Street, Toronto, Ontario)"
                                /> */}

                                <PlaceAutocomplete onPlaceSelect={setPlaceSelected} />
                            </div>

                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                Radius
                            </label>
                            <div className="flex gap-x-4 py-5 ">
                                <RadiusSlider currentValue={sliderValue} setCurrentValue={setSliderValue} />
                                {/* <input
                                    id="radius-text"
                                    name="radius-text"
                                    type="text"
                                    autoComplete="text"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Enter your radius in KM (e.g. 0.5)"
                                /> */}
                            </div>
                        </div>

                        {showInvalidRadiusAlert && <Alert />}

                        <p className="mt-4 text-sm leading-6 text-gray-900">
                            After signing up, you can re-use your email for future logins and get started {' '}
                            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                right away!&nbsp;
                                {/* subscribed! */}
                            </a>
                        </p >
                    </form >
                    <button
                        onClick={() => {
                            // setShowLoadingModal(true);
                            const stringSliderValue = '' + (sliderValue / 1000);

                            // add the values to our redux store 

                            // dispatch({ type: 'SET_SIGNUP_EMAIL', payload: document.getElementById("email-address").value });
                            // dispatch({ type: 'SET_RADIUS', payload: stringSliderValue });
                            // dispatch({ type: 'SET_LOCATION', payload: placeSelected.formatted_address });

                            // Promise.all
                            //     (

                            dispatch(setSignUpEmail(document.getElementById("email-address").value));
                            dispatch(setRadius(stringSliderValue));
                            dispatch(setLocation(placeSelected.formatted_address))

                            createStripeSession(document.getElementById("email-address").value, placeSelected.formatted_address, stringSliderValue);



                            // dispatch(setPaymentScreenFlag(true));

                            // )
                            // .then(() => {
                            //     console.log("states updated");
                            //     console.log(inputEmail);
                            //     console.log(inputLocation);
                            //     console.log(inputRadius)
                            // })

                            // TODO: UNCOMMENT AFTER TESTING REDUX STORE
                            // const requestOptions = {
                            //     method: 'POST',
                            //     headers: { 'Content-Type': 'application/json' },
                            //     body: JSON.stringify({
                            //         email: document.getElementById("email-address").value,
                            //         location: document.getElementById("location-text").value,
                            //         radius: sliderValue / 1000
                            //     })
                            // };

                            // fetch('https://api.jaoo.ca/registerUser', requestOptions)
                            //     // fetch('https://imgzhqpwuzng4on2m25xjufzva0kelod.lambda-url.us-east-2.on.aws/', requestOptions)
                            //     .then(response => response.json())
                            //     .then(data => handleResponse(data, document.getElementById("email-address").value))
                        }}
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        type="button"
                    >
                        Submit
                    </button>
                    <button
                        onClick={() => {
                            setShowForm(false); setFirstTimeUser(false)
                        }}
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                        Go Back
                    </button>

                </div >
            </div >
        )
    }

    function logUserOut() {
        setSignedIn(false);
        setUserEmail("");
        setShowForm(false);
        setFirstTimeUser(false);
        setFaqs([]);
        setPosition({ lat: 53.54992, lng: 10.00678 });
    }

    function showChat(position) {
        return (
            <div key={signedIn.valueOf.name}>
                <WebSocketCall position={position} email={userEmail} faqQuestions={faqs} setFaqQuestions={setFaqs} />
                <button
                    onClick={() => {
                        logUserOut();
                    }}
                    type="button"
                    className="mt-5 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                    Log Out
                </button>
            </div>
        )
    }

    function showAlreadySubscribedForm() {
        return (
            <div key={signedIn.valueOf.name}>
                <p className="mt-6 text-gray-500">Enter the email you subscribed with initially to sign back in, once you're logged in enter your food related questions in the chat below and submit!</p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                    <div className="sm:col-span-2 sm:col-start-1">
                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="flex gap-x-4">
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter your email"
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            setShowForm(false);
                            setFirstTimeUser(false);

                            // build out a request first
                            var currentEmail = document.getElementById("email-address").value;
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                //body: JSON.stringify({ email: document.getElementById("email-address").value })
                                body: JSON.stringify({ email: currentEmail })
                            };

                            //</div>fetch('http://ec2-18-191-214-35.us-east-2.compute.amazonaws.com:5000/returnUser', requestOptions)
                            fetch('https://api.jaoo.ca/returnUser', requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    if (data['result'] == 'success') {
                                        // convert each element in location array from string to a number

                                        var newPosition = data['location'].map(Number)

                                        setPosition({ lat: newPosition[0], lng: newPosition[1] });
                                        setSignedIn(true);
                                        setUserEmail(currentEmail);
                                    }
                                });
                        }}
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"

                    >
                        Submit
                    </button>
                    <button
                        onClick={() => {
                            setShowForm(false); setFirstTimeUser(false)
                        }}
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                        Go Back
                    </button>

                </div>
            </div>
        )
    }

    const deleteUserFromPlatforms = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: "anuptaislam33@gmail.com",
                subscription_id: "ch_3PuADe2NcE2VbmHR0b6R717R"
            })
        };

        fetch('https://api.jaoo.ca/deleteUserFromPlatforms', requestOptions)
        console.log("yo")
    }
    return (
        <div className="bg-white">
            {/* move this map to the left side */}
            {/*  */}

            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            {/* <img
                                className="h-8 w-auto"
                                // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                src={screenshot}
                                alt=""
                            /> */}
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <Link to={"/" + item.name} >
                                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                    {item.name}
                                </a>
                            </Link>

                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">

                    </div>
                </nav>
            </header>

            <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                {/* Product */}
                {showLoadingModal && <LoadingModal setShowLoadingModal={setShowLoadingModal} abortController={abortController} />}
                <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                    {/* Product image */}

                    <div key={position.lat} className="lg:col-span-4 lg:row-end-1">
                        <div style={{ width: '100%', height: '400px' }}>
                            {/* <APIProvider key={position.lat} apiKey={'AIzaSyCBHQT3ISdGKNfWn7sxdb-EWNNpotEDYjM'}> */}
                            <Map key={position.lat} defaultCenter={position} defaultZoom={16}>
                                <Marker key={position.lat} position={position} />
                            </Map>
                            {/* </APIProvider> */}
                        </div>
                    </div>

                    {/* Product details */}
                    <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                        <div className="flex flex-col-reverse">
                            <div className="mt-4">
                                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>

                                <h2 id="information-heading" className="sr-only">
                                    Product information
                                </h2>
                                <p className="mt-2 text-sm text-gray-500">
                                    Version {product.version.name} (Updated{' '}
                                    <time dateTime={product.version.datetime}>{product.version.date}</time>)
                                </p>
                            </div>

                            <div>
                                <h3 className="sr-only">Reviews</h3>
                                <div className="flex items-center">
                                    <ChatBubbleLeftIcon className="h-5 w-5 flex-shrink-0 text-yellow-400" aria-hidden="true" />
                                </div>
                                <p className="sr-only">{reviews.average} out of 5 stars</p>
                            </div>
                        </div>

                        {signedIn ? showChat(position) : showForm ? (
                            // nested logic within here so we need a second state variable

                            firstTimeUser ? (inputPaymentScreenFlag ? <StripePaymentButton /> : showFirstTimeUserForm()) : (showAlreadySubscribedForm())
                        ) : (
                            showProductButtons()
                        )}

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium text-gray-900">Share</h3>
                            <ul role="list" className="mt-4 flex items-center space-x-6">
                                <li>
                                    <a href="https://www.instagram.com/jaobites/" className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Share on Instagram</span>
                                        <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                fillRule="evenodd"
                                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                {/* <li>
                                    <button
                                        onClick={() => {

                                            // delete from mongoDB
                                            // cancel subscription from Stripe
                                            // delete from dynamoDB 

                                            deleteUserFromPlatforms();
                                            logUserOut();
                                        }}
                                        type="button"
                                        className="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                    >
                                        Cancel subscription
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                        <Tab.Group as="div">
                            <div className="border-b border-gray-200">
                                <Tab.List className="-mb-px flex space-x-8">
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                                selected
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                            )
                                        }
                                    >
                                        Previous Questions
                                    </Tab>
                                </Tab.List>
                            </div>
                            <Tab.Panels as={Fragment}>
                                <Tab.Panel className="text-sm text-gray-500">
                                    <h3 className="sr-only">Frequently Asked Questions</h3>

                                    <dl>
                                        {faqs.map((faq) => (
                                            <Fragment key={faq.question}>
                                                <dt className="mt-10 font-medium text-gray-900">{faq.question}</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                                                    <ReactMarkdown>{faq.answer}</ReactMarkdown>
                                                    {/*<p>{faq.answer}</p> */}
                                                </dd>
                                            </Fragment>
                                        ))}
                                    </dl>
                                </Tab.Panel>

                                <Tab.Panel className="pt-10">
                                    <h3 className="sr-only">License</h3>

                                    <div
                                        className="prose prose-sm max-w-none text-gray-500"
                                        dangerouslySetInnerHTML={{ __html: license.content }}
                                    />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div >
    )
}
